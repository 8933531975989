<template>
	<svg
		v-bind="$attrs"
		viewBox="0 0 32 32"
		xmlns="http://www.w3.org/2000/svg"
	>
		<path
			fill-rule="evenodd"
			clip-rule="evenodd"
			d="M15.946 7.6167C12.386 7.6167 9.5 10.5027 9.5 14.0627C9.5 17.6227 12.386 20.5086 15.946 20.5086C19.506 20.5086 22.3919 17.6227 22.3919 14.0627C22.3919 10.5027 19.506 7.6167 15.946 7.6167ZM11.3919 14.0627C11.3919 11.5475 13.4308 9.50861 15.946 9.50861C18.4611 9.50861 20.5 11.5475 20.5 14.0627C20.5 16.5778 18.4611 18.6167 15.946 18.6167C13.4308 18.6167 11.3919 16.5778 11.3919 14.0627Z"
		></path>
		<path
			fill-rule="evenodd"
			clip-rule="evenodd"
			d="M15.946 3.0542C13.0598 3.0542 10.2887 4.1853 8.22943 6.20459L8.22739 6.2066C7.20508 7.21517 6.39337 8.41627 5.83934 9.74032C5.2853 11.0644 5 12.4851 5 13.9201C5 15.3551 5.2853 16.7757 5.83934 18.0998C6.39301 19.423 7.20403 20.6234 8.22539 21.6316L8.22739 21.6335L15.2777 28.6697C15.647 29.0382 16.2449 29.0382 16.6142 28.6697L23.6645 21.6335L23.6664 21.6317C24.6878 20.6235 25.4989 19.4231 26.0526 18.0998C26.6066 16.7757 26.8919 15.3551 26.8919 13.9201C26.8919 12.4851 26.6066 11.0644 26.0526 9.74032C25.4985 8.41627 24.6868 7.21517 23.6645 6.2066L23.6625 6.20459C21.6032 4.1853 18.8321 3.0542 15.946 3.0542ZM7.58462 10.4706C8.04172 9.3782 8.71143 8.38699 9.55507 7.55442C11.2601 5.88296 13.5551 4.94611 15.946 4.94611C18.3369 4.94611 20.6318 5.88296 22.3368 7.55442C23.1805 8.38699 23.8502 9.3782 24.3073 10.4706C24.7646 11.5634 25 12.7359 25 13.9201C25 15.1043 24.7646 16.2767 24.3073 17.3695C23.85 18.4624 23.1799 19.4539 22.3358 20.2867L15.946 26.6637L9.55997 20.2905L9.55609 20.2867C8.71197 19.4539 8.0419 18.4624 7.58462 17.3695C7.12734 16.2767 6.89191 15.1043 6.89191 13.9201C6.89191 12.7359 7.12734 11.5634 7.58462 10.4706Z"
		></path>
	</svg>
</template>

<script>
export default defineComponent({
	name: "IconLocation",
});
</script>
